import { DefaultView } from '@plone/volto/components';

import { defineMessages } from 'react-intl';
import GraduationHatSVG from '@package/svgs/icons/graduation-hat.svg';
import GraduationHatsSVG from '@package/svgs/icons/graduation-hats.svg';

import MetadataEventViewBlock from '@package/components/Blocks/MetadataEvent/View';
import MetadataEventEditBlock from '@package/components/Blocks/MetadataEvent/Edit';
import calendarSVG from '@plone/volto/icons/calendar.svg';

import PreviewImageViewBlock from '@package/components/Blocks/PreviewImage/View';

import PersonViewBlock from '@package/components/Blocks/Person/View';
import PersonEditBlock from '@package/components/Blocks/Person/Edit';
import userSVG from '@plone/volto/icons/user.svg';

import PersonFunctionViewBlock from '@package/components/Blocks/PersonFunction/View';
import PersonFunctionEditBlock from '@package/components/Blocks/PersonFunction/Edit';
import groupSVG from '@plone/volto/icons/group.svg';

import LectureViewBlock from '@package/components/Blocks/Lecture/View';
import LectureEditBlock from '@package/components/Blocks/Lecture/Edit';

import LecturesViewBlock from '@package/components/Blocks/Lectures/View';
import LecturesEditBlock from '@package/components/Blocks/Lectures/Edit';

import ImageCardViewBlock from '@package/components/Blocks/ImageCard/View';
import ImageCardEditBlock from '@package/components/Blocks/ImageCard/Edit';
import imageSVG from '@plone/volto/icons/image.svg';

import TeaserViewBlock from '@package/components/Blocks/Teaser/View';
import TeaserEditBlock from '@package/components/Blocks/Teaser/Edit';
import imagesSVG from '@plone/volto/icons/images.svg';

import PublicationsViewBlock from '@package/components/Blocks/Publications/View';
import PublicationsEditBlock from '@package/components/Blocks/Publications/Edit';
import PublicationsSVG from '@plone/volto/icons/content-listing.svg';

import ResearchProjectsViewBlock from '@package/components/Blocks/ResearchProjects/View';
import ResearchProjectsEditBlock from '@package/components/Blocks/ResearchProjects/Edit';

import ResearchProjectsECampusViewBlock from '@package/components/Blocks/ResearchProjectsECampus/View';
import ResearchProjectsECampusEditBlock from '@package/components/Blocks/ResearchProjectsECampus/Edit';

import BibliographyBlock from '@package/components/Blocks/Bibliography/View';
import bookSVG from '@plone/volto/icons/book.svg';

import PrimoSearchBlock from '@package/components/Blocks/PrimoSearch/View';

import ButtonViewBlock from '@package/components/Blocks/Button/View';
import ButtonEditBlock from '@package/components/Blocks/Button/Edit';
import buttonSVG from '@plone/volto/icons/image-wide.svg';

import StatsViewBlock from '@package/components/Blocks/Stats/View';
import StatsEditBlock from '@package/components/Blocks/Stats/Edit';
import triangleSVG from '@plone/volto/icons/triangle.svg';

import QuicklinksViewBlock from '@package/components/Blocks/Quicklinks/View';
import QuicklinksEditBlock from '@package/components/Blocks/Quicklinks/Edit';
import fastForwardSVG from '@plone/volto/icons/fast-forward.svg';

import RelatedItemsViewBlock from '@package/components/Blocks/RelatedItems/View';
import RelatedItemsEditBlock from '@package/components/Blocks/RelatedItems/Edit';
import moreSVG from '@plone/volto/icons/more.svg';

import DescriptionViewBlock from '@package/components/Blocks/Description/View';
import descriptionSVG from '@plone/volto/icons/description.svg';

import MetadataPostViewBlock from '@package/components/Blocks/MetadataPost/View';
import MetadataPostEditBlock from '@package/components/Blocks/MetadataPost/Edit';

import MetadataJobPostViewBlock from '@package/components/Blocks/MetadataJobPost/View';
import MetadataJobPostEditBlock from '@package/components/Blocks/MetadataJobPost/Edit';
import documentDetailsSVG from '@plone/volto/icons/doument-details.svg';

import CountdownViewBlock from '@package/components/Blocks/Countdown/View';
import CountdownEditBlock from '@package/components/Blocks/Countdown/Edit';
import clockSVG from '@plone/volto/icons/clock.svg';

import PanoptoVideoViewBlock from '@package/components/Blocks/PanoptoVideo/View';
import PanoptoVideoEditBlock from '@package/components/Blocks/PanoptoVideo/Edit';
import videoSVG from '@plone/volto/icons/video.svg';

import BlogPostSVG from '@plone/volto/icons/blog-entry.svg';
import homeSVG from '@plone/volto/icons/home.svg';
import ShowcaseSVG from '@plone/volto/icons/showcase.svg';
import ContentExistingSVG from '@plone/volto/icons/content-existing.svg';

import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';

import DividerViewBlock from '@package/components/Blocks/Divider/View';
import DividerEditBlock from '@package/components/Blocks/Divider/Edit';
import divideSVG from '@plone/volto/icons/divide-horizontal.svg';

import AudioViewBlock from '@package/components/Blocks/Audio/View';
import AudioEditBlock from '@package/components/Blocks/Audio/Edit';
import volumeSVG from '@plone/volto/icons/volume.svg';

import ToCViewBlock from '@package/components/Blocks/ToC/View';
import ToCEditBlock from '@package/components/Blocks/ToC/Edit';

import EventsViewBlock from '@package/components/Blocks/Events/View';
import EventsEditBlock from '@package/components/Blocks/Events/Edit';

import DegreeProgramViewBlock from '@package/components/Blocks/DegreeProgram/View';
import DegreeProgramEditBlock from '@package/components/Blocks/DegreeProgram/Edit';

import PhoneBookViewBlock from '@package/components/Blocks/PhoneBook/View';

import TabsViewBlock from '@package/components/Blocks/Tabs/View';
import TabsEditBlock from '@package/components/Blocks/Tabs/Edit';
import TabsWidget from '@package/components/Blocks/Tabs/TabsWidget';
import TabsSVG from '@package/svgs/icons/tabs.svg';

import RSSFeedViewBlock from '@package/components/Blocks/RSSFeed/View';
import RSSFeedEditBlock from '@package/components/Blocks/RSSFeed/Edit';
import rssSVG from '@plone/volto/icons/rss.svg';

import NdFViewBlock from '@package/components/Blocks/NdF/View';

import { Login } from '@plone/volto/components';
import TokenWidget from '@plone/volto/components/manage/Widgets/TokenWidget';

import DoctorateView from '@package/components/Views/DoctorateView.jsx';
import JobPostView from '@package/components/Views/JobPostView';

import renderLinkElement from '@package/slate/renderLinkElement';

import {
  AlbumListing,
  CarouselListing,
  CertificateProgramView,
  DegreeProgramView,
  DegreeProgramsSearchView,
  ECampusProjectSelectorWidget,
  EventsCarousel,
  EventsListing,
  FunctionsOrderWidget,
  GlobalBanner,
  HeadingsSelectWidget,
  HelmetAdapter,
  HeroGallery,
  LecturesSelectWidget,
  LinkList,
  ListOfTitleAndTextWidget,
  FAQsWidget,
  FieldsRemarksWidget,
  FullscreenGallery,
  FunctionPersonsWidget,
  FurtherLinksWidget,
  MaterialsWidget,
  NewsItemsGrid,
  NewsItemsListing,
  PersonFunctionView,
  PersonView,
  PersonsListing,
  PersonsOverrideWidget,
  PhoneWidget,
  ProjectsListing,
  RerouteHelper,
  ResearchProjectView,
  ResearchProjectFundingWidget,
  ResearchProjectPartnerWidget,
  SearchResults,
  ShortURLWidget,
  SubjectProfileView,
  SubjectProfileSearchView,
  SubjectsWidget,
  TableListing,
  VideosWidget,
  WorklistPage,
  WorklistWidget,
  // SidebarNavigation,
} from '@package/components';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';
import Shibboleth from '@package/components/Views/Logins/Shibboleth.jsx';

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly

  defineMessages({
    translate_with_deepl: {
      id: 'Translate with DeepL',
      defaultMessage: 'Translate with DeepL',
    },
    automated_translation: {
      id: 'Automated translation',
      defaultMessage: 'Automated translation',
    },
    hint_check_deepl_translations: {
      id: 'hint_check_deepl_translations',
      defaultMessage:
        'Please check the automatically translated content before applying the changes!',
    },
    carousel: {
      id: 'Carousel',
      defaultMessage: 'Carousel',
    },
    eventscarousel: {
      id: 'Events Carousel',
      defaultMessage: 'Events Carousel',
    },
    personfunction: {
      id: 'Person Function',
      defaultMessage: 'Person Function',
    },
    personFunction: {
      id: 'PersonFunction',
      defaultMessage: 'Person Function',
    },
    lectures: {
      id: 'lectures',
      defaultMessage: 'Lectures',
    },
    orgunit: {
      id: 'orgunit',
      defaultMessage: 'Organisational Unit',
    },
    orgUnit: {
      id: 'OrgUnit',
      defaultMessage: 'Organisational Unit',
    },
    program: {
      id: 'Degree program',
      defaultMessage: 'Degree program',
    },
    phonebook: {
      id: 'Phone book',
      defaultMessage: 'Phone book',
    },
    person: {
      id: 'Person',
      defaultMessage: 'Person',
    },
    cookieBannerButton: {
      id: 'cookie_banner_button',
      defaultMessage: 'OK',
    },
    columns: {
      id: 'Columns',
      defaultMessage: 'Columns',
    },
    imagecard: {
      id: 'ImageCard',
      defaultMessage: 'ImageCard',
    },
    previewimage: {
      id: 'Preview image',
      defaultMessage: 'Preview image',
    },
    leafletmap: {
      id: 'Leaflet map',
      defaultMessage: 'Leaflet map',
    },
    events: {
      id: 'Events',
      defaultMessage: 'Events',
    },
    newsitems: {
      id: 'News Items',
      defaultMessage: 'News Items',
    },
    newsitemsgrid: {
      id: 'News Items Grid',
      defaultMessage: 'News Items Grid',
    },
    publications: {
      id: 'Publications',
      defaultMessage: 'Publications',
    },
    researchprojects: {
      id: 'Research projects',
      defaultMessage: 'Research projects',
    },
    researchprojects_ecampus: {
      id: 'Research projects from eCampus',
      defaultMessage: 'Research projects from eCampus',
    },
    researchPoject: {
      id: 'ResearchProject',
      defaultMessage: 'Research project',
    },
    divider: {
      id: 'Divider',
      defaultMessage: 'Divider',
    },
    notAllowed: {
      id: 'Not allowed',
      defaultMessage: 'Not allowed',
    },
    applyWorkingCopyNotAllowed: {
      id: 'You may not make changes to a published page.',
      defaultMessage: 'You may not make changes to a published page.',
    },
    reset: {
      id: 'form_reset',
      defaultMessage: 'Clear',
    },
    useAsReplyTo: {
      id: 'form_useAsReplyTo',
      defaultMessage: "Use as 'reply to'",
    },
    useAsReplyTo_description: {
      id: 'form_useAsReplyTo_description',
      defaultMessage:
        'If selected, this will be the address the receiver can use to reply.',
    },
    useAsBCC: {
      id: 'form_useAsBCC',
      defaultMessage: 'Send an email copy to this address',
    },
    useAsBCC_description: {
      id: 'form_useAsBCC_description',
      defaultMessage:
        'If selected, a copy of email will alse be sent to this address.',
    },
    no_translation: {
      id: 'no_translation',
      defaultMessage: 'Keine Übersetzung vorhanden',
    },
    no_translation_description: {
      id: 'no_translation_description',
      defaultMessage:
        'Leider existiert für diese Seite keine Übersetzung. Folgen Sie diesem Link zu',
    },
    next_translated_parent: {
      id: 'next_translated_parent',
      defaultMessage: 'einer darüberliegenden Übersetzung',
    },
    downloadEvent: {
      id: 'Download Event',
      defaultMessage: 'Download Event',
    },
    quicklinks: {
      id: 'Quicklinks',
      defaultMessage: 'Quicklinks',
    },
    readMore: {
      id: 'Read more',
      defaultMessage: 'Read more',
    },
    addUnauthorized0: {
      id: 'You are not allowed to create a translation at this location ({path}).',
      defaultMessage:
        'You are not allowed to create a translation at this location ({path}).',
    },
    addUnauthorized1: {
      id: 'If you still want this translation to be created at this location, please <mail>contact the support</mail>.',
      defaultMessage:
        'If you still want this translation to be created at this location, please <mail>contact the support</mail>.',
    },
    from: {
      id: 'from',
      defaultMessage: 'from',
    },
    to: {
      id: 'to',
      defaultMessage: 'to',
    },
    starting_at: {
      id: 'starting_at',
      defaultMessage: 'at',
    },
    report_error: {
      id: 'Report error',
      defaultMessage: 'Report error',
    },
    please_report_error: {
      id: 'Please report this error by providing the URL of the affected page and a detailed description of previous activities.',
      defaultMessage:
        'Please report this error by providing the URL of the affected page and a detailed description of previous activities.',
    },
    degreeProgram: {
      id: 'DegreeProgram',
      defaultMessage: 'DegreeProgram',
    },
    linklist: {
      id: 'Link list',
      defaultMessage: 'Link list',
    },
    /* override from SkipLinks.jsx */
    mainView: {
      id: 'skiplink-main-content',
      defaultMessage: 'Skip to main content',
    },
    navigation: {
      id: 'skiplink-navigation',
      defaultMessage: 'Skip to navigation',
    },
    footer: {
      id: 'skiplink-footer',
      defaultMessage: 'Skip to footer',
    },
    certificateProgram: {
      id: 'CertificateProgram',
      defaultMessage: 'Certificate Program',
    },
    /* following are missing in Volto core -
       check from time to time if still needed:
    */
    tableHideHeaders: {
      id: 'Hide headers',
      defaultMessage: 'Hide headers',
    },
    tableSortable: {
      id: 'Make the table sortable',
      defaultMessage: 'Make the table sortable',
    },
    tableBlock: {
      id: 'Table block',
      defaultMessage: 'Table block',
    },
    /* volto/src/components/manage/Contents/Contents */
    brokenReferences: {
      id: 'Deleting this item breaks {brokenReferences} {variation}.',
      defaultMessage:
        'Deleting this item breaks {brokenReferences} {variation}.',
    },
    brokenReferencesPlural: {
      id: 'Some items are referenced by other contents. By deleting them {brokenReferences} {variation} will be broken.',
      defaultMessage:
        'Some items are referenced by other contents. By deleting them {brokenReferences} {variation} will be broken.',
    },
    containedItemsToDelete: {
      id: 'This item is also a folder. By deleting it you will delete {containedItemsToDelete} {variation} inside the folder.',
      defaultMessage:
        'This item is also a folder. By deleting it you will delete {containedItemsToDelete} {variation} inside the folder.',
    },
    containedItemsToDeletePlural: {
      id: 'Some items are also a folder. By deleting them you will delete {containedItemsToDelete} {variation} inside the folders.',
      defaultMessage:
        'Some items are also a folder. By deleting them you will delete {containedItemsToDelete} {variation} inside the folders.',
    },
    willHaveBrokenLinks: {
      id: 'These items will have broken links',
      defaultMessage: 'These items will have broken links',
    },
    navigateToItem: {
      id: 'Navigate to this item',
      defaultMessage: 'Navigate to this item',
    },
    viewLinksAndReferences: {
      id: 'View links and references to this item',
      defaultMessage: 'View links and references to this item',
    },
    delete: {
      id: 'Delete',
      defaultMessage: 'Delete',
    },
    deleteConfirmSingleItem: {
      id: 'Delete this item?',
      defaultMessage: 'Delete this item?',
    },
    deleteConfirmMultipleItems: {
      id: 'Delete selected items?',
      defaultMessage: 'Delete selected items?',
    },
    deleteError: {
      id: 'The item could not be deleted.',
      defaultMessage: 'The item could not be deleted.',
    },
    item: {
      id: 'item',
      defaultMessage: 'item',
    },
    items: {
      id: 'items',
      defaultMessage: 'items',
    },
    reference: {
      id: 'reference',
      defaultMessage: 'reference',
    },
    references: {
      id: 'references',
      defaultMessage: 'references',
    },
    refersTo: {
      id: 'refers to',
      defaultMessage: 'refers to',
    },
    deleteAndBreak: {
      id: 'Delete item and break links',
      defaultMessage: 'Delete item and break links',
    },
    itemWillBeDeleted: {
      id: 'Following item will be deleted',
      defaultMessage: 'Following item will be deleted',
    },
    itemsWillBeDeleted: {
      id: 'Following {itemsToDelete} items will be deleted',
      defaultMessage: 'Following {itemsToDelete} items will be deleted',
    },
    selectNone: {
      id: 'Select none',
      defaultMessage: 'None',
    },
    postMetadata: {
      id: 'Post metadata',
      defaultMessage: 'Post metadata',
    },
    jobPostMetadata: {
      id: 'Job post metadata',
      defaultMessage: 'Job post metadata',
    },
    eventMetadata: {
      id: 'Event metadata',
      defaultMessage: 'Event metadata',
    },
    /* override strings from volto-form-block */
    send_otp_to: {
      id: 'form_send_otp_to',
      defaultMessage: 'Click here to send OTP code to {email}',
    },
    /* might be removed when translation is in Volto core */
    image_upload_widget_label: {
      id: 'Browse the site, drop an image, or type a URL',
      defaultMessage: 'Browse the site, drop an image, or type a URL',
    },
  });

  config.blocks.blocksConfig.person = {
    id: 'person',
    title: 'Person',
    icon: userSVG,
    group: 'person',
    view: PersonViewBlock,
    edit: PersonEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.personfunction = {
    id: 'personfunction',
    title: 'Person Function',
    icon: groupSVG,
    group: 'person',
    view: PersonFunctionViewBlock,
    edit: PersonFunctionEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.lecture = {
    id: 'lecture',
    title: 'Lecture',
    icon: GraduationHatSVG,
    group: 'person',
    view: LectureViewBlock,
    edit: LectureEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.lectures = {
    id: 'lectures',
    title: 'Lectures',
    icon: GraduationHatsSVG,
    group: 'person',
    view: LecturesViewBlock,
    edit: LecturesEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.imagecard = {
    id: 'imagecard',
    title: 'ImageCard',
    icon: imageSVG,
    group: 'common',
    view: ImageCardViewBlock,
    edit: ImageCardEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.events = {
    id: 'events',
    title: 'Events',
    icon: calendarSVG,
    group: 'social',
    view: EventsViewBlock,
    edit: EventsEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.metadataevent = {
    id: 'metadataevent',
    title: 'Event metadata',
    icon: documentDetailsSVG,
    group: 'common',
    view: MetadataEventViewBlock,
    edit: MetadataEventEditBlock,
    restricted: true,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.metadatapost = {
    id: 'metadatapost',
    title: 'Post metadata',
    icon: documentDetailsSVG,
    group: 'common',
    view: MetadataPostViewBlock,
    edit: MetadataPostEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.metadatajobpost = {
    id: 'metadatajobpost',
    title: 'Job post metadata',
    icon: documentDetailsSVG,
    group: 'common',
    view: MetadataJobPostViewBlock,
    edit: MetadataJobPostEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.previewimage = {
    id: 'previewimage',
    title: 'Preview image',
    icon: imageSVG,
    group: 'common',
    view: PreviewImageViewBlock,
    edit: PreviewImageViewBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 0,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.teaser = {
    id: 'teaser',
    title: 'Teaser',
    icon: imagesSVG,
    group: 'common',
    view: TeaserViewBlock,
    edit: TeaserEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.publications = {
    id: 'publications',
    title: 'Publications',
    icon: PublicationsSVG,
    group: 'person',
    view: PublicationsViewBlock,
    edit: PublicationsEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.researchprojects = {
    id: 'researchprojects',
    title: 'Research projects',
    icon: PublicationsSVG,
    group: 'person',
    view: ResearchProjectsViewBlock,
    edit: ResearchProjectsEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.researchprojects_ecampus = {
    id: 'researchprojects_ecampus',
    title: 'Research projects from eCampus',
    icon: PublicationsSVG,
    group: 'person',
    view: ResearchProjectsECampusViewBlock,
    edit: ResearchProjectsECampusEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.program = {
    id: 'program',
    title: 'Degree program',
    icon: GraduationHatSVG,
    group: 'common',
    view: DegreeProgramViewBlock,
    edit: DegreeProgramEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.primosearch = {
    id: 'primosearch',
    title: 'KatalogPLUS Suche',
    icon: bookSVG,
    group: 'experimental',
    view: PrimoSearchBlock,
    edit: PrimoSearchBlock,
    restricted: true,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.bibliography = {
    id: 'bibliography',
    title: 'Bibliography',
    icon: bookSVG,
    group: 'experimental',
    view: BibliographyBlock,
    edit: BibliographyBlock,
    restricted: true,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.phonebook = {
    id: 'phonebook',
    title: 'Phone Book',
    icon: bookSVG,
    group: 'experimental',
    view: PhoneBookViewBlock,
    edit: PhoneBookViewBlock,
    restricted: true,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.c2a_button = {
    id: 'c2a_button',
    title: 'Button',
    icon: buttonSVG,
    group: 'common',
    view: ButtonViewBlock,
    edit: ButtonEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.stats = {
    id: 'stats',
    title: 'Stats',
    icon: triangleSVG,
    group: 'common',
    view: StatsViewBlock,
    edit: StatsEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  /* keep id `navpills` for compatibility reasons */
  config.blocks.blocksConfig.navpills = {
    id: 'navpills',
    title: 'Quicklinks',
    icon: fastForwardSVG,
    group: 'layout',
    view: QuicklinksViewBlock,
    edit: QuicklinksEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.relateditems = {
    id: 'relateditems',
    title: 'Related items',
    icon: moreSVG,
    group: 'common',
    view: RelatedItemsViewBlock,
    edit: RelatedItemsEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.description = {
    id: 'description',
    title: 'Description',
    icon: descriptionSVG,
    group: 'common',
    view: DescriptionViewBlock,
    edit: DescriptionViewBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 0,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.countdown = {
    id: 'countdown',
    title: 'Countdown',
    icon: clockSVG,
    group: 'common',
    view: CountdownViewBlock,
    edit: CountdownEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.panopto = {
    id: 'panopto',
    title: 'Panopto Video',
    icon: videoSVG,
    group: 'media',
    view: PanoptoVideoViewBlock,
    edit: PanoptoVideoEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.hrblock = {
    id: 'hrblock',
    title: 'Divider',
    icon: divideSVG,
    group: 'layout',
    view: DividerViewBlock,
    edit: DividerEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.audio = {
    id: 'audio',
    title: 'Audio',
    icon: volumeSVG,
    group: 'media',
    view: AudioViewBlock,
    edit: AudioEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.ndf = {
    id: 'ndf',
    title: 'Nacht der Forschung Programm',
    icon: PublicationsSVG,
    group: 'social',
    view: NdFViewBlock,
    edit: NdFViewBlock,
    restricted: true,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  /* Most code of this block is borrowed from
     https://github.com/eea/volto-tabs-block

     Why dont use the addon then?
     > The addon provides tons of style customization settings,
       which we do not want to provide to our editors.
       Removing / hiding them would be at least as complicated
       as isolating the code parts we actually need and implement
       block for ourselfs, with the nice benefit of less impact
       on the bundle size.
  */
  config.blocks.blocksConfig.tabs = {
    id: 'tabs',
    title: 'Tabs',
    icon: TabsSVG,
    group: 'layout',
    view: TabsViewBlock,
    edit: TabsEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    blockHasOwnFocusManagement: true,
  };
  config.blocks.blocksConfig.rssfeed = {
    id: 'rssfeed',
    title: 'RSS-Feed',
    icon: rssSVG,
    group: 'social',
    view: RSSFeedViewBlock,
    edit: RSSFeedEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  /* custom widget used in sidebar of tabs block */
  config.widgets.type.tabs = TabsWidget;

  let additionalGroups = [
    { id: 'layout', title: 'Layout' },
    { id: 'experimental', title: 'Experimental' },
    { id: 'person', title: 'Person' },
    { id: 'social', title: 'Social' },
  ];

  config.blocks.groupBlocksOrder =
    config.blocks.groupBlocksOrder.concat(additionalGroups);

  config.blocks.requiredBlocks = []; // make title not required (is by default)

  // disable Google Maps block
  delete config.blocks.blocksConfig.maps;

  // use openstreetmap.de instead of .org
  config.blocks.blocksConfig.leafletMap.tilesLayerUrl =
    'http://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png';

  // delete default grid block (introduced in Volto 17.0.0-alpha.16),
  // since we use eea/volto-columns-block
  delete config.blocks.blocksConfig.gridBlock;

  // restrict HTML block, but do not disable it,
  // since it should still be possible to use it
  // when its allowed via IAllowHTMLBlock behavior
  config.blocks.blocksConfig.html.restricted = true;

  // clean up mostUsed section
  config.blocks.blocksConfig.form.mostUsed = false;
  config.blocks.blocksConfig.video.mostUsed = false;

  // adapt schema of slate block
  config.blocks.blocksConfig.slate.schemaEnhancer = ({ schema, intl }) => {
    // only allow level h2 to h4 (see helpers/blocks.js: getToCEntries)
    schema.properties.level.choices = [
      ['h2', 'Level 1'],
      ['h3', 'Level 2'],
      ['h4', 'Level 3'],
    ];
    return schema;
  };

  // adapt schema of slateTable block to hide 'inverted' option,
  // because we do not want black tables with white font color
  config.blocks.blocksConfig.slateTable.schemaEnhancer = ({ schema, intl }) => {
    schema.properties.table.schema.fieldsets[0].fields =
      schema.properties.table.schema.fieldsets[0].fields.slice(0, -1);
    delete schema.properties.table.schema.properties.inverted;
    return schema;
  };

  // adapt schema of image block
  config.blocks.blocksConfig.image.schemaEnhancer = ({ schema, intl }) => {
    const messages = defineMessages({
      labelCaption: {
        id: 'Caption',
        defaultMessage: 'Caption',
      },
      labelHide: {
        id: 'Hide',
        defaultMessage: 'Hide',
      },
      descrHide: {
        id: 'Hide image on selected screen sizes.',
        defaultMessage: 'Hide image on selected screen sizes.',
      },
      labelLightbox: {
        id: 'Lightbox',
        defaultMessage: 'Lightbox',
      },
      descrLightbox: {
        id: 'A click on the image opens a full-screen view as a pop-up.',
        defaultMessage:
          'A click on the image opens a full-screen view as a pop-up.',
      },
    });

    schema.properties.caption = {
      title: intl.formatMessage(messages.labelCaption),
    };
    schema.fieldsets[0].fields.push('caption');

    schema.properties.hideonscreens = {
      title: intl.formatMessage(messages.labelHide),
      description: intl.formatMessage(messages.descrHide),
      type: 'string',
      factory: 'Choice',
      choices: [
        ['mobile', 'Mobile'],
        ['tablet', 'Tablet'],
        ['computer', 'Computer'],
        ['large screen', 'Large Screen'],
      ],
      isMulti: true,
    };
    schema.fieldsets[0].fields.push('hideonscreens');

    schema.properties.lightbox = {
      title: intl.formatMessage(messages.labelLightbox),
      description: intl.formatMessage(messages.descrLightbox),
      type: 'boolean',
    };
    schema.fieldsets[0].fields.push('lightbox');

    return schema;
  };

  // adapt settings for https://github.com/eea/volto-accordion-block
  config.blocks.blocksConfig.accordion.group = 'layout';
  config.blocks.blocksConfig.accordion.mostUsed = true;
  /* we want to change icon direction via css transform
    (see `accordion.less`) */
  config.blocks.blocksConfig.accordion.titleIcons.closed = {
    leftPosition: arrowSVG,
    rightPosition: arrowSVG,
  };
  config.blocks.blocksConfig.accordion.titleIcons.opened = {
    leftPosition: arrowSVG,
    rightPosition: arrowSVG,
  };
  config.blocks.blocksConfig.accordion.titleIcons.size = '14px';
  config.blocks.blocksConfig.accordion.schemaEnhancer = ({ schema, intl }) => {
    const messages = defineMessages({
      labelUniKoArrow: {
        id: 'Display UniKo-Arrow',
        defaultMessage: 'Display UniKo-Arrow',
      },
      descrUniKoArrow: {
        id: 'in the background',
        defaultMessage: 'in the background',
      },
    });
    /* only h2, h3 and h4 as optional title sizes,
       since h5 and h6 dont differ from default text */
    schema.properties.title_size.choices = [
      ['h2', 'Heading 2'],
      ['h3', 'Heading 3'],
      ['h4', 'Heading 4'],
    ];
    schema.properties.show_uniko_arrow = {
      title: intl.formatMessage(messages.labelUniKoArrow),
      description: intl.formatMessage(messages.descrUniKoArrow),
      type: 'boolean',
      default: false,
    };
    schema.fieldsets[0].fields.push('show_uniko_arrow');
    return schema;
  };

  // adapt settings for https://github.com/eea/volto-columns-block
  config.blocks.blocksConfig.columnsBlock.group = 'layout';
  config.blocks.blocksConfig.columnsBlock.mostUsed = true;
  config.blocks.blocksConfig.columnsBlock.available_colors = [
    '#c61a27',
    '#000000',
    '#3c3c3b',
    '#7b7b7a',
    '#ececec',
  ];
  config.blocks.blocksConfig.columnsBlock.schema.properties.allowedBlocks.items.choices =
    config.blocks.blocksConfig.columnsBlock.schema.properties.allowedBlocks.items.choices.concat(
      [
        ['imagecard', 'ImageCard'],
        ['leafletMap', 'Leaflet Map'],
        ['form', 'Form'],
        ['person', 'Person'],
        ['metadataevent', 'Metadata Event'],
        ['metadatapost', 'Metadata Post'],
        ['description', 'Description'],
        ['c2a_button', 'Button'],
        ['audio', 'Audio'],
        ['panopto', 'Panopto Video'],
        ['divider', 'Divider'],
      ],
    );

  /* use custom components for the ToC block */
  config.blocks.blocksConfig.toc.view = ToCViewBlock;
  config.blocks.blocksConfig.toc.edit = ToCEditBlock;
  config.blocks.blocksConfig.toc.sidebarTab = 1;

  /* extend schema of Listing Block */
  config.blocks.blocksConfig.listing.schemaEnhancer = ({ schema, intl }) => {
    const messages = defineMessages({
      labelAllowedFilters: {
        id: 'Allowed filters',
        defaultMessage: 'Allowed filters',
      },
      descrAllowedFilters: {
        id: 'Users can further filter the listing. Only work reliably if at least one criterion has been defined for the listing.',
        defaultMessage:
          'Users can further filter the listing. Only work reliably if at least one criterion has been defined for the listing.',
      },
      labelType: {
        id: 'Type',
        defaultMessage: 'Type',
      },
      labelTags: {
        id: 'Tags',
        defaultMessage: 'Tags',
      },
      labelAlwaysDisplayFiltersAsDropdown: {
        id: 'Always display filters as dropdown',
        defaultMessage: 'Always display filters as dropdown',
      },
      descrAlwaysDisplayFiltersAsDropdown: {
        id: 'Otherwise filters will only be displayed as dropdowns on small screens.',
        defaultMessage:
          'Otherwise filters will only be displayed as dropdowns on small screens.',
      },
    });

    schema.properties.allowed_filters = {
      title: intl.formatMessage(messages.labelAllowedFilters),
      description: intl.formatMessage(messages.descrAllowedFilters),
      type: 'string',
      factory: 'Choice',
      choices: [
        ['type', intl.formatMessage(messages.labelType)],
        ['tags', intl.formatMessage(messages.labelTags)],
      ],
      isMulti: true,
      default: [],
    };
    schema.fieldsets[0].fields.push('allowed_filters');

    schema.properties.always_display_filters_as_dropdown = {
      title: intl.formatMessage(messages.labelAlwaysDisplayFiltersAsDropdown),
      description: intl.formatMessage(
        messages.descrAlwaysDisplayFiltersAsDropdown,
      ),
      type: 'boolean',
    };
    schema.fieldsets[0].fields.push('always_display_filters_as_dropdown');

    /* delete headline-fields since we do not use them */
    schema.fieldsets[0].fields = schema.fieldsets[0].fields.filter(
      (i) => !['headline', 'headlineTag'].includes(i),
    );

    return schema;
  };

  /* additional fields for schema of existing variation `default` */
  const variationDefault = config.blocks.blocksConfig.listing.variations.find(
    (item) => item.id === 'default',
  );
  variationDefault.schemaEnhancer = ({ schema, intl }) => {
    const messages = defineMessages({
      labelShowPreviewImages: {
        id: 'Show images',
        defaultMessage: 'Show images',
      },
      labelHideDescriptions: {
        id: 'Hide descriptions',
        defaultMessage: 'Hide descriptions',
      },
      labelCTAText: {
        id: 'Call-to-Action',
        defaultMessage: 'Call-to-Action',
      },
      readMore: {
        id: 'Read more',
        defaultMessage: 'Read more',
      },
    });
    schema.properties.cta_text = {
      title: intl.formatMessage(messages.labelCTAText),
      type: 'text',
    };
    schema.fieldsets[0].fields.push('cta_text');
    schema.properties.show_preview_images = {
      title: intl.formatMessage(messages.labelShowPreviewImages),
      type: 'boolean',
      default: false,
    };
    schema.fieldsets[0].fields.push('show_preview_images');
    schema.properties.hide_descriptions = {
      title: intl.formatMessage(messages.labelHideDescriptions),
      type: 'boolean',
      default: false,
    };
    schema.fieldsets[0].fields.push('hide_descriptions');
    return schema;
  };

  /* additional fields for schema of existing variation `summary` */
  const variationSummary = config.blocks.blocksConfig.listing.variations.find(
    (item) => item.id === 'summary',
  );
  variationSummary.schemaEnhancer = ({ schema, intl }) => {
    const messages = defineMessages({
      labelDisableSlider: {
        id: 'Disable slider',
        defaultMessage: 'Disable slider',
      },
      labelHidePreviewImages: {
        id: 'Hide images',
        defaultMessage: 'Hide images',
      },
      labelHideDescriptions: {
        id: 'Hide descriptions',
        defaultMessage: 'Hide descriptions',
      },
      labelCTAText: {
        id: 'Call-to-Action',
        defaultMessage: 'Call-to-Action',
      },
      readMore: {
        id: 'Read more',
        defaultMessage: 'Read more',
      },
    });
    schema.properties.cta_text = {
      title: intl.formatMessage(messages.labelCTAText),
      type: 'text',
    };
    schema.fieldsets[0].fields.push('cta_text');
    schema.properties.disable_slider = {
      title: intl.formatMessage(messages.labelDisableSlider),
      type: 'boolean',
      default: false,
    };
    schema.fieldsets[0].fields.push('disable_slider');
    schema.properties.hide_preview_images = {
      title: intl.formatMessage(messages.labelHidePreviewImages),
      type: 'boolean',
      default: false,
    };
    schema.fieldsets[0].fields.push('hide_preview_images');
    schema.properties.hide_descriptions = {
      title: intl.formatMessage(messages.labelHideDescriptions),
      type: 'boolean',
      default: false,
    };
    schema.fieldsets[0].fields.push('hide_descriptions');
    return schema;
  };

  config.blocks.blocksConfig.listing.variations =
    config.blocks.blocksConfig.listing.variations.concat([
      {
        id: 'carousel',
        isDefault: false,
        title: 'Carousel',
        template: CarouselListing,
        schemaEnhancer: ({ schema, intl }) => {
          const messages = defineMessages({
            labelHidePreviewImages: {
              id: 'Hide images',
              defaultMessage: 'Hide images',
            },
            labelHideDescriptions: {
              id: 'Hide descriptions',
              defaultMessage: 'Hide descriptions',
            },
            labelCTAText: {
              id: 'Call-to-Action',
              defaultMessage: 'Call-to-Action',
            },
            readMore: {
              id: 'Read more',
              defaultMessage: 'Read more',
            },
          });

          schema.properties.hide_preview_images = {
            title: intl.formatMessage(messages.labelHidePreviewImages),
            type: 'boolean',
            default: false,
          };
          schema.fieldsets[0].fields.push('hide_preview_images');

          schema.properties.hide_descriptions = {
            title: intl.formatMessage(messages.labelHideDescriptions),
            type: 'boolean',
            default: false,
          };
          schema.fieldsets[0].fields.push('hide_descriptions');

          schema.properties.cta_text = {
            title: intl.formatMessage(messages.labelCTAText),
            type: 'text',
          };
          schema.fieldsets[0].fields.push('cta_text');

          schema.properties.autoplay_speed = {
            title: 'Autoplay speed',
            type: 'number',
            default: 0,
          };
          schema.fieldsets[0].fields.push('autoplay_speed');

          schema.properties.responsive = {
            title: 'Responsive JSON object',
            type: 'text',
            widget: 'textarea',
            default: `{
    "desktop": {
        "breakpoint": {
            "max": 9999999,
            "min": 1400
        },
        "items": 3,
        "partialVisibilityGutter": 0
    },
    "tablet": {
        "breakpoint": {
            "max": 1399,
            "min": 992
        },
        "items": 2,
        "partialVisibilityGutter": 30
    },
    "mobile": {
        "breakpoint": {
            "max": 991,
            "min": 0
        },
        "items": 1,
        "partialVisibilityGutter": 90
    }
}`,
          };
          schema.fieldsets[0].fields.push('responsive');

          schema.properties.max_image_height = {
            title: 'Maximum image height',
            description: 'If value is 0 image max-height will be auto.',
            type: 'number',
            default: 0,
          };
          schema.fieldsets[0].fields.push('max_image_height');

          return schema;
        },
      },
      {
        id: 'events',
        isDefault: false,
        title: 'Events',
        template: EventsListing,
        schemaEnhancer: ({ schema, intl }) => {
          const messages = defineMessages({
            labelShowTime: {
              id: 'Show time',
              defaultMessage: 'Show time',
            },
            labelShowDescription: {
              id: 'Show description',
              defaultMessage: 'Show description',
            },
          });
          schema.properties.show_time = {
            title: intl.formatMessage(messages.labelShowTime),
            type: 'boolean',
            default: false,
          };
          schema.properties.show_description = {
            title: intl.formatMessage(messages.labelShowDescription),
            type: 'boolean',
            default: false,
          };
          schema.fieldsets[0].fields.push('show_time');
          schema.fieldsets[0].fields.push('show_description');
          return schema;
        },
      },
      {
        id: 'eventscarousel',
        isDefault: false,
        title: 'Events Carousel',
        template: EventsCarousel,
        schemaEnhancer: ({ schema, intl }) => {
          const messages = defineMessages({
            labelLinkToAll: {
              id: 'All events overview',
              defaultMessage: 'All events overview',
            },
            descrLinkToAll: {
              id: 'Will be linked in the carousel.',
              defaultMessage: 'Will be linked in the carousel.',
            },
            labelShowTime: {
              id: 'Show time',
              defaultMessage: 'Show time',
            },
            labelShowDescription: {
              id: 'Show description',
              defaultMessage: 'Show description',
            },
          });
          schema.properties.link_to_all = {
            title: intl.formatMessage(messages.labelLinkToAll),
            description: intl.formatMessage(messages.descrLinkToAll),
            widget: 'object_browser',
            mode: 'link',
            selectedItemAttrs: ['Title', 'Description'],
          };
          schema.properties.show_time = {
            title: intl.formatMessage(messages.labelShowTime),
            type: 'boolean',
            default: false,
          };
          schema.properties.show_description = {
            title: intl.formatMessage(messages.labelShowDescription),
            type: 'boolean',
            default: false,
          };
          schema.fieldsets[0].fields.push('show_time');
          schema.fieldsets[0].fields.push('show_description');
          schema.fieldsets[0].fields.push('link_to_all');
          return schema;
        },
      },
      {
        id: 'newsitems',
        isDefault: false,
        title: 'News Items',
        template: NewsItemsListing,
        schemaEnhancer: ({ schema, intl }) => {
          const messages = defineMessages({
            labelShowImages: {
              id: 'Show images',
              defaultMessage: 'Show images',
            },
          });
          schema.properties.show_images = {
            title: intl.formatMessage(messages.labelShowImages),
            type: 'boolean',
            default: false,
          };
          schema.fieldsets[0].fields.push('show_images');
          return schema;
        },
      },
      {
        id: 'newsitemsgrid',
        isDefault: false,
        title: 'News Items Grid',
        template: NewsItemsGrid,
        schemaEnhancer: ({ schema, intl }) => {
          const messages = defineMessages({
            labelNoImages: {
              id: 'No images',
              defaultMessage: 'No images',
            },
            labelFeatureFirstItem: {
              id: 'Feature first item',
              defaultMessage: 'Feature first item',
            },
            labelGroupByMonth: {
              id: 'Group by month',
              defaultMessage: 'Group by month',
            },
            descrGroupByMonth: {
              id: 'Only works reliable if listing is sorted by date.',
              defaultMessage:
                'Only works reliable if listing is sorted by date.',
            },
          });
          schema.properties.feature_first_item = {
            title: intl.formatMessage(messages.labelFeatureFirstItem),
            type: 'boolean',
            default: false,
          };
          schema.properties.no_images = {
            title: intl.formatMessage(messages.labelNoImages),
            type: 'boolean',
            default: false,
          };
          schema.properties.group_by_month = {
            title: intl.formatMessage(messages.labelGroupByMonth),
            description: intl.formatMessage(messages.descrGroupByMonth),
            type: 'boolean',
            default: false,
          };
          schema.fieldsets[0].fields.push('feature_first_item');
          schema.fieldsets[0].fields.push('no_images');
          schema.fieldsets[0].fields.push('group_by_month');
          delete schema.properties.headline;
          delete schema.properties.headlineTag;
          return schema;
        },
      },
      {
        id: 'linklist',
        isDefault: false,
        title: 'Link list',
        template: LinkList,
      },
      {
        id: 'herogallery',
        isDefault: false,
        title: 'Hero Gallery',
        template: HeroGallery,
      },
      {
        id: 'searchresults',
        isDefault: false,
        title: 'Search results',
        template: SearchResults,
      },
      {
        id: 'projects',
        isDefault: false,
        title: 'Research projects',
        template: ProjectsListing,
      },
      {
        id: 'persons',
        isDefault: false,
        title: 'Persons',
        template: PersonsListing,
      },
      {
        id: 'album',
        isDefault: false,
        title: 'Album',
        template: AlbumListing,
      },
      {
        id: 'table',
        isDefault: false,
        title: 'Table',
        template: TableListing,
      },
    ]);

  config.views.contentTypesViews.CertificateProgram = CertificateProgramView;
  config.views.contentTypesViews.DegreeProgram = DegreeProgramView;
  config.views.contentTypesViews.Doctorate = DoctorateView;
  config.views.contentTypesViews.SubjectProfile = SubjectProfileView;
  config.views.contentTypesViews['News Item'] = DefaultView;
  config.views.contentTypesViews.Event = DefaultView;
  config.views.contentTypesViews.Person = PersonView;
  config.views.contentTypesViews.PersonFunction = PersonFunctionView;
  config.views.contentTypesViews.ResearchProject = ResearchProjectView;

  config.views.layoutViews.degree_programs_search = DegreeProgramsSearchView;
  config.views.layoutViewsNamesMapping.degree_programs_search =
    'Degree Programs Search View';
  config.views.layoutViews.subject_profile_search = SubjectProfileSearchView;
  config.views.layoutViewsNamesMapping.subject_profile_search =
    'Subject Profiles Search View';
  config.views.layoutViews.job_post_view = JobPostView;

  config.widgets.widget.function_persons_widget = FunctionPersonsWidget;
  config.widgets.widget.ecampus_project_selector_widget =
    ECampusProjectSelectorWidget;
  config.widgets.widget.project_funding_widget = ResearchProjectFundingWidget;
  config.widgets.widget.project_partner_widget = ResearchProjectPartnerWidget;
  config.widgets.widget.videos_widget = VideosWidget;
  config.widgets.widget.list_of_title_and_text_widget =
    ListOfTitleAndTextWidget;
  config.widgets.widget.lsf_heading = HeadingsSelectWidget;
  config.widgets.widget.telephone_widget = PhoneWidget;
  config.widgets.widget.faqs_widget = FAQsWidget;
  config.widgets.widget.fields_remarks_widget = FieldsRemarksWidget;
  config.widgets.widget.materials_widget = MaterialsWidget;
  config.widgets.widget.further_links_widget = FurtherLinksWidget;
  config.widgets.widget.persons_override_widget = PersonsOverrideWidget;
  config.widgets.id.lectures_to_display = LecturesSelectWidget;
  config.widgets.id.lsf_heading = HeadingsSelectWidget;
  config.widgets.id.functions_order = FunctionsOrderWidget;
  config.widgets.id.subjects = SubjectsWidget;
  config.widgets.id.admission_requirements = TokenWidget;
  config.widgets.id.degree_program_topics = TokenWidget;
  config.widgets.id.school_subjects = TokenWidget;
  config.widgets.id.school_subjects_with_admission_restrictions = TokenWidget;

  /* add callout to toolbar buttons of slate editor */
  config.settings.slate.toolbarButtons.push('callout');

  /* fix https://gitlab.uni-koblenz.de/cms-developer/volto-uniko/-/issues/712 */
  config.settings.slate.elements.h1 = renderLinkElement('h1');
  config.settings.slate.elements.h2 = renderLinkElement('h2');
  config.settings.slate.elements.h3 = renderLinkElement('h3');
  config.settings.slate.elements.h4 = renderLinkElement('h4');

  config.settings.image_crop_apect_ratios = [
    {
      label: 'Header',
      ratio: 96 / 31,
    },
    {
      label: '16:9',
      ratio: 16 / 9,
    },
    {
      label: '4:3',
      ratio: 4 / 3,
    },
    {
      label: '1:1',
      ratio: 1,
    },
  ];

  /* multilang support */
  config.settings.isMultilingual = true;
  config.settings.supportedLanguages = ['de', 'en'];
  config.settings.defaultLanguage = 'de';

  /* hide original tags component in order to show ours
     (see customizations/volto/components/View/View) */
  config.settings.showTags = false;
  config.settings.useEmailAsLogin = true;

  /* working copy support
      (https://docs.voltocms.com/configuration/workingcopy/)
  */
  config.settings.hasWorkingCopySupport = true;
  config.settings.workingCopyAllowedTypes = [
    'Document',
    'Event',
    'News Item',
    'Link',
    'Person',
    'PersonFunction',
    'OrgUnit',
    'ResearchProject',
  ];

  config.settings.appExtras = config.settings.appExtras.concat([
    {
      match: '',
      component: GlobalBanner,
    },
    {
      match: '',
      component: ShortURLWidget,
    },
    {
      match: '',
      component: WorklistWidget,
    },
    {
      match: '',
      component: HelmetAdapter,
    },
  ]);

  config.addonRoutes.push(
    {
      path: '/worklist',
      component: WorklistPage,
    },
    {
      path: '/passwordreset',
      component: RerouteHelper,
    },
    {
      path: '/register',
      component: RerouteHelper,
    },
    {
      path: '/Service',
      component: RerouteHelper,
    },
    {
      path: '/search',
      component: RerouteHelper,
    },
    {
      path: '**/fullscreen_gallery',
      component: FullscreenGallery,
    },
    /* replace original login by our Shibboleth component */
    {
      path: ['/login', '/**/login'],
      component: Shibboleth,
    },
    /* but keep local login available */
    {
      path: '**/local-login',
      component: Login,
    },
  );

  if (__SERVER__) {
    const rss = require('./express-middleware/rss.js');
    config.settings.expressMiddleware.push(rss.default());

    const renderBlocksStatic = require('./express-middleware/render_blocks_static.js');
    config.settings.expressMiddleware.push(renderBlocksStatic.default());
  }

  config.settings.apiExpanders = [
    ...config.settings.apiExpanders,
    {
      match: '',
      GET_CONTENT: ['customizations', 'globalbanner', 'partials', 'subpages'],
    },
  ];

  config.settings.contentIcons.Person = userSVG;
  config.settings.contentIcons.PersonFunction = groupSVG;
  config.settings.contentIcons.OrgUnit = homeSVG;
  config.settings.contentIcons['ResearchProject'] = ShowcaseSVG;
  config.settings.contentIcons['Blogpost'] = BlogPostSVG;
  config.settings.contentIcons['DegreeProgram'] = GraduationHatSVG;
  config.settings.contentIcons['Doctorate'] = GraduationHatSVG;
  config.settings.contentIcons['CertificateProgram'] = GraduationHatSVG;
  config.settings.contentIcons['SubjectProfile'] = ContentExistingSVG;

  /* used in HelmetAdapter */
  if (!config.settings.plausible) {
    /* only set if not set already by another addon (volto-weipo for example) */
    config.settings.plausible = {
      dataDomain: 'uni-koblenz.de',
      src: 'https://plausible.uni-koblenz.de/js/script.pageview-props.tagged-events.outbound-links.file-downloads.js',
    };
  }

  if (!config.settings.shibboleth) {
    config.settings.shibboleth = {
      endpoint: 'https://www.uni-koblenz.de/Shibboleth.sso',
    };
  }

  /* dont hydrate following reducers: */
  config.settings.initialReducersBlacklist = [
    /* unused by anons */
    'exportCsvFormData',
    /* hydrating this reducer means writing all data from
       `@navigation` endpoint into JS, which is actually
       never used ...
    */
    'navigation',
  ];

  return config;
}
